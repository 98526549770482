import { ModalContainerService } from "@aecom/core";
import { Component } from "@angular/core";
import { BBJFileListService } from "@bbj/components";
import TransmittalFileType from "@models/TransmittalFileType";
import TransmittalWithdrawStep from "@models/transmittalWithdrawStep";
import BaseFunctionService from "@services/baseFunction.service";
import LocalContractUserService from "@services/local-contractUser.service";

@Component({
  selector: "app-withdraw-modal",
  templateUrl: "./withdraw-modal.component.html",
  styleUrls: ["./withdraw-modal.component.scss"]
})
export class WithdrawModalComponent {
  item: TransmittalWithdrawStep;

  attemptToIssue = false;

  notesMaxLength = 2000;

  set setItem(item: TransmittalWithdrawStep) {
    this.item = item;
    this.bbjFileListService.initItem([{files:[],title:TransmittalFileType.Attachment}]);
    this.baseFunctionService.setTempId(item.tempId);
  }

  constructor(
    private activeModal: ModalContainerService,
    public baseFunctionService: BaseFunctionService,
    public localContractUserService: LocalContractUserService,
    public bbjFileListService: BBJFileListService
  ) {}

  get transmittalFileType(): typeof TransmittalFileType {
    return TransmittalFileType;
  }
  
  save() {
    this.attemptToIssue = true;

    if (this.bbjFileListService.hasFilesUploading()) {
      this.bbjFileListService.showFileProgressNotification();
    } else if (this.isValid()) {
      this.bbjFileListService.updateFiles(this.item);
      this.activeModal.close(this.item);
    }
  }

  isValid(): boolean {
    return this.item.transmittal_withdraw.Notes && this.item.transmittal_withdraw.Notes.length > 0;
  }

  cancel() {
    this.activeModal.close(null);
  }

  getNotesLength(): number {
    return this.item.transmittal_withdraw.Notes
      ? this.item.transmittal_withdraw.Notes.length
      : 0;
  }
}
