/* eslint-disable import/no-extraneous-dependencies */
import { AecomCoreModule, LayoutService } from "@aecom/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ApiModule, BASE_PATH } from "@api";
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import {
  Configuration,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";
import { AzureBlobService, BBJCoreModule, BBJFileListService, BBJSelectUserService } from "@bbj/components";
import CreateComponent from "@content/transmittal/create/create.component";
import IssuedComponent from "@content/transmittal/issued/issued.component";
import ListComponent from "@content/transmittal/list/list.component";
import ViewComponent from "@content/transmittal/view/view.component";
import CreateStepResolver from "@resolvers/createStep.resolver";
import ListResolver from "@resolvers/list.resolver";
import ViewResolver from "@resolvers/view.resolver";
import LocalContractUserService from "@services/local-contractUser.service";
import LocalUserService from "@services/local-user.service";
import NavService from "@services/nav.service";
import TransmittalDetailComponent from "@shared/transmittalDetail/transmittalDetail.component";
import TransmittalHeaderComponent from "@shared/transmittalHeader/transmittalHeader.component";
import TransmittalProcessingComponent from "@shared/transmittalProcessing/transmittalProcessing.component";
import LeftPanelComponent from "@shared/leftPanel/leftPanel.component";
import ResponseDetailComponent from "@shared/responseDetail/responseDetail.component";
import { NgxSpinnerModule } from "ngx-spinner";
import environment from "src/environments/environment";
import { PreviousRouteService } from "@services/previousRoute.service";
import ErrorComponent from "@content/error/error.component";
import { OAuthSettings } from "../oauth";
import AppRoutingModule from "./app-routing.module";
import AppComponent from "./app.component";
import AuthInterceptor from "./content/auth/auth.interceptor";
import AuthService from "./content/auth/auth.service";
import BaseComponent from "./content/base/base.component";
import SignoutComponent from "./content/signout/signout.component";
import { PendingChangesGuard } from "./guards/pending-changes.guard";
import ErrorResolve from "./resolvers/error-resolver";
import LoginUserResolve from "./resolvers/login-user-resolver";
import LocalContractService from "./services/local-contract.service";
import LocalStorageService from "./services/local-storage.service";
import { StorageKey } from "./shared/localstorage";
import { WorkflowHistoryComponent } from "@shared/workflowHistory/workflowHistory.component";
import BaseFunctionService from "@services/baseFunction.service";
import { WithdrawModalComponent } from "@shared/withdraw-modal/withdraw-modal.component";

export function tokenGetter(): string {
  return localStorage.getItem(StorageKey.TOKEN);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: OAuthSettings.appId,
      authority: OAuthSettings.authority,
      redirectUri: environment.url.redirectUri,
      postLogoutRedirectUri: environment.url.logoutURL,
    },
    cache: {
      cacheLocation: "localStorage",
      // set to true for IE 11
      storeAuthStateInCookie: false,
    },
  });
}

function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: OAuthSettings.appId,
      authority: OAuthSettings.authority,
      redirectUri: environment.url.redirectUri,
      postLogoutRedirectUri: environment.url.logoutURL,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: "localStorage",
      // set to true for IE 11
      storeAuthStateInCookie: false,
    },
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: OAuthSettings.protectedResourceMap,
    // unprotectedResources: OAuthSettings.unprotectedResources,
  };
}

function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: OAuthSettings.scopes,
    },
    loginFailedRoute: environment.url.redirectUri,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    ErrorComponent,
    TransmittalHeaderComponent,
    TransmittalDetailComponent,
    TransmittalProcessingComponent,
    ResponseDetailComponent,
    LeftPanelComponent,
    CreateComponent,
    IssuedComponent,
    ListComponent,
    ViewComponent,
    SignoutComponent,
    WorkflowHistoryComponent,
    WithdrawModalComponent
  ],
  imports: [
    AecomCoreModule,
    BBJCoreModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    MsalModule.forRoot(
      MSALInstanceFactory(),
      MSALGuardConfigFactory(),
      MSALInterceptorConfigFactory(),
    ),
    BrowserAnimationsModule,
    ApiModule,
  ],
  providers: [
    PendingChangesGuard,
    BaseFunctionService,
    BBJFileListService,
    BBJSelectUserService,
    LocalStorageService,
    LocalContractService,
    LocalContractUserService,
    LocalUserService,
    PreviousRouteService,
    NavService,
    AuthService,
    LayoutService,
    AzureBlobService,
    LoginUserResolve,
    ErrorResolve,
    ListResolver,
    ViewResolver,
    CreateStepResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    { provide: BASE_PATH, useValue: environment.baseWebAPI },
    MsalBroadcastService,
    MsalService,
    MsalGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
