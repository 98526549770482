/* eslint-disable security/detect-non-literal-fs-filename */
/* eslint-disable security/detect-object-injection */
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import AuthService from "@auth/auth.service";
import LoadingService from "@services/loading.service";
import _ from "lodash";
import {
  TransmittalItemService,
  ITransmittalDocumentDownload,
  ITransmittalListItem,
} from "@api";
import IViewPrepare from "@models/IViewPrepaer";
import LocalContractUserService from "@services/local-contractUser.service";
import TransmittalRole from "@models/TransmittalRole";
import TransmittalView from "@models/transmittalView";
import TransmittalStatus from "@models/TransmittalStatus";
import TransmittalUpdateBase from "@models/TransmittalUpdateBase";
import { HttpErrorResponse } from "@angular/common/http";
import BaseFunctionService from "@services/baseFunction.service";

@Component({
  selector: "app-view",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"],
})
export default class ViewComponent implements OnInit {
  entity: ITransmittalListItem;

  currentUser: string;

  docs: ITransmittalDocumentDownload[] = [];

  transmittal: TransmittalView;

  isDbAdmin = false;

  title = "Response";

  clipboardText = "NO Responded YET";

  userSelectTitle = "cc";

  constructor(
    public router: Router,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public localContractUserService: LocalContractUserService,
    public transmittalItemService: TransmittalItemService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    const data: IViewPrepare | undefined =
      this.activatedRoute.snapshot.data.itemData;
    this.currentUser = this.authService.user.Id;
    const role = this.localContractUserService.currentUserContractRole;
    this.isDbAdmin = role === TransmittalRole.DBAdmin;
    const isDoc = role === TransmittalRole.DocControl;
    if (data) {
      const { item, documents } = data;
      this.entity = item;
      this.docs = documents;
      this.transmittal = new TransmittalView(
        item,
        documents,
        this.localContractUserService.getItems(),
      );
      this.baseFunctionService.initCCUsers(this.userSelectTitle, true);
      if (
        isDoc !== true &&
        this.entity.Status !== TransmittalStatus.Filed &&
        this.entity.Status !== TransmittalStatus.Responded
      ) {
        this.title = "Updates";
        this.clipboardText =
          this.entity.Status === TransmittalStatus.Submitted
            ? "Submitted"
            : "Received";
      }
      if (
        this.isDbAdmin &&
        this.entity.BallInCourt.includes(TransmittalRole.DBAdmin) &&
        this.entity.Viewed !== true &&
        (this.entity.Status === TransmittalStatus.Filed ||
          this.entity.Status === TransmittalStatus.Responded)
      ) {
        const viewTransmittal = new TransmittalUpdateBase(
          this.entity.ContractId,
          this.entity,
        );

        this.transmittalItemService
          .transmittalUpdate(viewTransmittal)
          .subscribe(
            (r) => {
              console.log(r);
            },
            (error: HttpErrorResponse) => {
              console.log(error);
            },
          );
      }
    }
  }

  back(): void {
    this.router.navigateByUrl(`${this.entity.ContractId}/list`);
  }
}
