<div *ngIf="data" class="grid-container noTableFilter timelineTable">
  <div class="timelineTable">
    <div class="row">
      <div class="col-1 th" style="text-align: left; position: relative; left: 40px;">#</div>
      <div class="col-2 th">Step</div>
      <div class="col-2 th">Modified By</div>
      <div class="col th">Updates</div>
      <div class="col-2 th">Time Submitted</div>
    </div>
    <ng-container *ngFor="let row of data; index as i">
      <div class="row tr">
        <div class="col-1 td" style="display: flex; position: relative; min-width: 80px;">
          <div *ngIf="row.Collapse!==undefined" style="cursor: pointer; margin-left: 10px; position: absolute;" [ngStyle]="{'top':row.Collapse ? '-7px' : '2px'}" (click)="row.Collapse = !row.Collapse">
            <i [ngClass]="row.Collapse ? 'icon-action_sort_desc' : 'icon-action_sort_asc'" class="icon icon-blue icon-md" style="background-color: #ccc;"></i>
          </div>
          <div class="circle" style="margin-top: 10px; margin-left: 30px; margin-right: auto;">
            <div style="margin: 0 auto">{{ i+1 }}</div>
          </div>
        </div>
        <div class="col-2 td">{{ row.Status }}</div>
        <div class="col-2 td">{{ getUserName(row.SubmittedBy) }}</div>
        <div class="col td">
          <div class="updateSession" *ngIf="row.showItems.length > 0; else notProvide">
            <ng-container *ngFor="let item of row.showItems;">
              <ng-container 
                  [ngTemplateOutlet]="lineItem" 
                  [ngTemplateOutletContext]="{item:item}">
              </ng-container>
            </ng-container>
            <ng-container *ngIf="row.collapseItems.length > 0;">
              <ng-container *ngIf="!row.Collapse; else ellipsis">
                <ng-container *ngFor="let item of row.collapseItems;">
                  <ng-container 
                      [ngTemplateOutlet]="lineItem" 
                      [ngTemplateOutletContext]="{item:item}">
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="col-2 td">{{ getFormatedDateAndTime(row.DateReceived) }}</div>
        <ng-container *ngIf="row.isVoid">
          <div class="col-1 td" *ngIf="!row.Collapse"></div>
          <div class="col-11 td" *ngIf="!row.Collapse">
            <div class="row" style="padding-bottom: 30px">
              <div class="col-11 innerTh">Notes</div>
              <div class="col-11" [innerHtml]="row.Notes" style="line-height: 1.4;"></div>
              <div class="col-11" style="margin-top: 10px;" *ngIf="row.Attachments && row.Attachments.length > 0">
                <bbj-file-list
                  [fileList]="row.Attachments"
                  title="Attachments"
                  [allowMovingFiles]="false"
                ></bbj-file-list>
              </div>
            </div>
          </div>
        </ng-container>
        
      </div>
    </ng-container>
  </div>
</div>

<ng-template #notProvide>
  <div>-</div>
</ng-template>
<ng-template #blank>
  <div></div>
</ng-template>
<ng-template #ellipsis>
  <div>...</div>
</ng-template>
<ng-template #lineItem let-item="item">
  <div *ngIf="item.type === 'bool'">
    <div style="width: 60%;">{{item.name}}</div>
    <div>{{item.value ? 'Yes':'No'}}</div>
  </div>
  <div *ngIf="item.type === 'date'">
    <div style="width: 60%;">{{item.name}}</div>
    <div *ngIf="item.value !== ''; else blank">
      {{ getFormatedDateMMDDYYYYY(item.value) }}
    </div>
  </div>
  <div *ngIf="item.type === 'key'">
    <div style="margin-right: 5px;">{{item.name}}</div>
    <div style="display: flex; flex-wrap: wrap" *ngIf="item.value.add.length > 0 || item.value.delete.length > 0; else notProvide">
      <ng-container *ngIf="item.value.delete.length > 0">
        <span>-</span>
        <ng-container *ngFor="let tag of item.value.delete">
          <div style="position: relative; margin-right: 6px; margin-bottom: 6px;">
            <div class="tag">
              {{tag}}
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="item.value.add.length > 0">
        <span>+</span>
        <ng-container *ngFor="let tag of item.value.add">
          <div style="position: relative; margin-right: 6px; margin-bottom: 6px;">
            <div class="tag">
              {{tag}}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div  *ngIf="item.type === 'cc'">
    <div style="margin-right: 5px;">{{item.name}}</div>
    <div>
      <ng-container *ngIf="item.value.delete.length > 0">
        <span>-</span>
        <ng-container *ngFor="let dCC of item.value.delete; index as k">
          <span>
            {{ getUserName(dCC) }}{{k===item.value.delete.length-1 ? "; " : ", "}}
          </span>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="item.value.add.length > 0">
        <span>+</span>
        <ng-container *ngFor="let aCC of item.value.add; index as k">
          <span>
            {{ getUserName(aCC) }}{{k===item.value.add.length-1 ? ";" : ","}}
          </span>
        </ng-container>
      </ng-container>
      
    </div>
  </div>
</ng-template>