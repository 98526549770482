/* eslint-disable sonarjs/cognitive-complexity */
import {
  ISelectItem,
  ModalContainerService,
  NotificationComponent,
} from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  CorrespondenceReferenceUser,
  ITransmittalListItem,
} from "@api";
import AuthService from "@auth/auth.service";
import { ComponentCanDeactivate } from "@guards/pending-changes.guard";
import TransmittalCreate from "@models/transmittalCreate";
import TransmittalFileType from "@models/TransmittalFileType";
import TransmittalRole from "@models/TransmittalRole";
import IViewPrepare from "@models/IViewPrepaer";
import LoadingService from "@services/loading.service";
import LocalContractService from "@services/local-contract.service";
import LocalContractUserService from "@services/local-contractUser.service";
import {
  transmittalDBRoldId,
  transmittalAppId,
  documentTypeTransmittalId,
} from "@shared/staticValue";
import {
  convertDateToUTCIOSString,
  getDateWithOffSet,
  validateInput,
} from "@shared/utils";
import _, { isString } from "lodash";
import { Observable } from "rxjs";
import LocalTransmittalDocumentTypeService from "@services/local-transmittalDocumentType.service";
import LocalTransmittalDetailsService from "@services/local-transmittalDetails.service";
import LocalUserService from "@services/local-user.service";
import BaseFunctionService from "@services/baseFunction.service";
import { BBJFileListService, BBJSelectUserService } from "@bbj/components";

@Component({
  selector: "app-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export default class CreateComponent implements OnInit, ComponentCanDeactivate {
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  title: string;

  transmittal: TransmittalCreate;

  oldTransmittal: TransmittalCreate;

  attemptToIssue = false;

  fromList: ISelectItem[];

  toList: ISelectItem[];

  documentTypeList: ISelectItem[];

  transmittalDetailList: ISelectItem[];

  contractId: string;

  currentUser: string;

  enableSaveDraft = false;

  LetterDate: Date;

  dueDate: Date | null = null;

  receivedDate: Date | null = null;

  today: Date;

  subjectMaxLength = 100;

  referenceMaxLength = 150;

  notesMaxLength = 250;

  allTags: string[] = [];

  isDB = true;

  userSelectTitle = "cc";

  constructor(
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public localContractService: LocalContractService,
    public activeModal: ModalContainerService,
    public localContractUserService: LocalContractUserService,
    public localTransmittalDocumentTypeService: LocalTransmittalDocumentTypeService,
    public localTransmittalDetailsService: LocalTransmittalDetailsService,
    public localUserService: LocalUserService,
    public baseFunctionService: BaseFunctionService,
    public bbjFileListService: BBJFileListService,
    public bbjSelectUserService: BBJSelectUserService
  ) {}

  ngOnInit(): void {
    const data: IViewPrepare | undefined =
      this.activatedRoute.snapshot.data.itemData;
    const { refUsers, topics } =
      this.activatedRoute.snapshot.data.createStepData;
    this.allTags = topics;
    this.currentUser = this.authService.user.Id;
    this.contractId = this.localContractService.currentContract.Guid;
    this.today = new Date();
    const userRole = this.localContractUserService.currentUserContractRole;
    this.isDB = userRole === TransmittalRole.DBAdmin;
    this.documentTypeList = this.localTransmittalDocumentTypeService
      .getItems()
      .map((item) => {
        return { name: item.Title, id: item.Guid, checked: false };
      });
    this.transmittalDetailList = this.localTransmittalDetailsService
      .getItems()
      .map((item) => {
        return { name: item.Title, id: item.Guid, checked: false };
      }).sort((a, b)=>{
        return a.name.localeCompare(b.name);
      });
    const userEmail = this.authService.user.Email;
    if (data) {
      const { item } = data;
      this.loadData(
        refUsers.sort(CreateComponent.compare),
        userEmail,
        item,
      );
    } else {
      this.loadData(refUsers.sort(CreateComponent.compare), userEmail);
    }

    this.loadingService.stop();
  }

  static compare(
    a: CorrespondenceReferenceUser,
    b: CorrespondenceReferenceUser,
  ): number {
    if (a.Name < b.Name) {
      return -1;
    }
    if (a.Name > b.Name) {
      return 1;
    }
    return 0;
  }

  loadData(
    users: CorrespondenceReferenceUser[],
    userEmail: string | null,
    transmittal?: ITransmittalListItem,
  ): void {
    if (this.isDB) {
      const contractUsers = this.localContractUserService.getItems();
      const contractDBs: CorrespondenceReferenceUser[] = [];
      const contractAHJVs: CorrespondenceReferenceUser[] = [];
      users.forEach((u) => {
        const user = contractUsers.find((cu) => {
          return cu.UserId === u.UserId && cu.DeletedBy === null;
        });
        if (user) {
          const transmittalRole = user.contract_user_application_role.find(
            (cuar) => {
              return cuar.ApplicationId === transmittalAppId;
            },
          );
          if (transmittalRole) {
            if (transmittalRole.ApplicationRoleId === transmittalDBRoldId) {
              contractDBs.push(u);
            } else {
              contractAHJVs.push(u);
            }
          }
        }
      });
      this.fromList = this.mappingRefUsers(contractDBs);
      this.toList = this.mappingRefUsers(contractAHJVs);
    } else {
      this.fromList = this.mappingRefUsers(users);
      this.toList = [...this.fromList];
    }

    const dbUser = this.isDB
      ? users.find(
          (u) =>
            u.Email?.toLocaleLowerCase() === userEmail?.toLocaleLowerCase(),
        )
      : undefined;
    this.oldTransmittal = new TransmittalCreate(
      this.contractId,
      this.isDB,
      transmittal,
      dbUser?.Guid,
    );
    this.dueDate = this.oldTransmittal.transmittal_issued?.DueDate
      ? new Date(
          getDateWithOffSet(this.oldTransmittal.transmittal_issued.DueDate),
        )
      : null;
    this.receivedDate = this.oldTransmittal.transmittal_issued?.ReceivedDate
      ? new Date(
          getDateWithOffSet(
            this.oldTransmittal.transmittal_issued.ReceivedDate,
          ),
        )
      : null;
    this.LetterDate = this.oldTransmittal.transmittal_create?.LetterDate
      ? new Date(
          getDateWithOffSet(this.oldTransmittal.transmittal_create.LetterDate),
        )
      : null;
    if (this.LetterDate) {
      this.oldTransmittal.transmittal_create.LetterDate =
        this.LetterDate.toISOString();
    }

    this.title = this.oldTransmittal.Guid
      ? "Draft Transmittal "
      : "Create New Transmittal";
    
    this.baseFunctionService.initCCUsers(this.userSelectTitle);
    console.log(this.oldTransmittal);
    this.baseFunctionService.initFileListforDraft();
    if(this.oldTransmittal.tempId)
    {
      this.baseFunctionService.setTempId(this.oldTransmittal.tempId);
    }
    this.transmittal = JSON.parse(JSON.stringify(this.oldTransmittal));
  }

  mappingRefUsers(users: CorrespondenceReferenceUser[]): ISelectItem[] {
    const contractCode = this.localContractService.currentContract.Code;
    const isPW = contractCode.includes("PW-");
    const adUsers = this.localUserService.getItems();
    return users
      .filter((u) => {
        return !isPW || u.ORG === "AHJV" || u.ORG === "DDC";
      })
      .map((item: CorrespondenceReferenceUser) => {
        let name = item.Name;
        if (!item.UserId || item.UserId === "") {
          name = `${item.Name} (Offline)`;
        } 
        else if(adUsers.find((u)=>{return u.id===item.UserId})?.accountEnabled !== true)
        {
          name = `${item.Name} (Offline)`;
        }
        return {
          name: `${name}, ${item.Title ?? "-"}, ${item.Organization ?? "-"}`,
          id: item.Guid,
          checked: false,
        };
      });
  }

  get transmittalFileType(): typeof TransmittalFileType {
    return TransmittalFileType;
  }

  hasChanges(): boolean {
    const old = JSON.stringify(this.oldTransmittal);
    const current = JSON.stringify(this.transmittal);
    return (
      old !== current || this.bbjFileListService.areFilesChanged() || this.bbjSelectUserService.hasChanges()
    );
  }

  IsInputValid(): boolean {
    const inValid = Object.values(this.transmittal.transmittal_create).find(
      (v) => {
        return (
          isString(v) && v !== null && v !== undefined && !validateInput(v)
        );
      },
    );
    return inValid === undefined;
  }

  IsValid(): boolean {
    if (
      !this.transmittal.transmittal_create.From ||
      this.transmittal.transmittal_create.From.trim() === "" ||
      !this.transmittal.transmittal_create.To ||
      this.transmittal.transmittal_create.To.trim() === "" ||
      !this.transmittal.transmittal_create.DocumentTypeId ||
      !this.transmittal.transmittal_create.LetterDate ||
      this.transmittal.transmittal_create.LetterDate.trim() === "" ||
      !this.transmittal.transmittal_create.Reference ||
      this.transmittal.transmittal_create.Reference.trim() === "" ||
      this.transmittal.transmittal_create.Reference.trim().length >
        this.referenceMaxLength ||
      !this.transmittal.transmittal_create.Subject ||
      this.transmittal.transmittal_create.Subject.trim() === "" ||
      this.transmittal.transmittal_create.Subject.trim().length >
        this.subjectMaxLength ||
      (this.transmittal.transmittal_create?.Note &&
        this.transmittal.transmittal_create.Note.trim().length >
          this.notesMaxLength) ||
      this.bbjFileListService.getFileList(TransmittalFileType.TransmittalFile).getCurrentFiles().length === 0 ||
      !this.bbjFileListService.isFileNameValid()
    ) {
      return false;
    }
    return true;
  }

  submit(): void {
    this.attemptToIssue = true;

    if (!this.IsInputValid() || !this.IsValid()) {
      return;
    }

    if (this.bbjFileListService.hasFilesUploading()) {
      this.bbjFileListService.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Submit Transmittal?";
      modalInstance.instance.body =
        "Once Transmittal is submitted, you will no longer be able to edit.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.transmittal.transmittal_create.IsDraft = false;
          if (!this.isDB && this.transmittal.transmittal_issued) {
            this.transmittal.transmittal_issued.IsDraft = false;
          }
          this.update();
        }
      });
    }
  }

  saveDraft(): void {
    if (!this.hasChanges() || !this.IsInputValid()) return;

    if (this.bbjFileListService.hasFilesUploading()) {
      this.bbjFileListService.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Save Draft?";
      modalInstance.instance.body = "Your Transmittal will save as draft.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.transmittal.transmittal_create.IsDraft = true;
          if (!this.isDB && this.transmittal.transmittal_issued) {
            this.transmittal.transmittal_issued.IsDraft = true;
          }
          this.update();
        }
      });
    }
  }

  update(): void {
    this.loadingService.start();

    if (!this.isDB && this.transmittal.transmittal_issued) {
      if (this.transmittal.transmittal_issued.ReceivedToday) {
        this.transmittal.transmittal_issued.ReceivedDate = null;
      }
      if (!this.transmittal.transmittal_issued.RequireResponse) {
        this.transmittal.transmittal_issued.DueDate = null;
      }
    } else {
      delete this.transmittal.transmittal_issued;
    }
    if (!this.showTransmittalDetail()) {
      this.transmittal.transmittal_create.TransmittalDetailId = null;
    }
    this.transmittal.transmittal_cc_user = this.bbjSelectUserService.getSelectedUserIds(this.userSelectTitle);
    console.log(this.transmittal);

    this.baseFunctionService.update(this.transmittal);
  }

  getSubjectLength(): number {
    return this.transmittal.transmittal_create?.Subject
      ? this.transmittal.transmittal_create?.Subject.length
      : 0;
  }

  getReferenceLength(): number {
    return this.transmittal.transmittal_create?.Reference
      ? this.transmittal.transmittal_create?.Reference.length
      : 0;
  }

  getNotesLength(): number {
    return this.transmittal.transmittal_create?.Note
      ? this.transmittal.transmittal_create?.Note.length
      : 0;
  }

  setFromUser(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.transmittal.transmittal_create.From = e.id;
    }
  }

  setToUser(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.transmittal.transmittal_create.To = e.id;
    }
  }

  setLetterDate(e: Date | string | any): void {
    if (_.isNil(e) || e.type === "change") return;
    if (e.target?.value === "") {
      this.LetterDate = null;
      this.transmittal.transmittal_create.LetterDate = null;
    } else {
      this.LetterDate = new Date(e);
      this.transmittal.transmittal_create.LetterDate =
        convertDateToUTCIOSString(this.LetterDate);
    }
  }

  setRefernce(e: string): void {
    this.transmittal.transmittal_create.Reference = e;
  }

  setSubject(e: string): void {
    this.transmittal.transmittal_create.Subject = e;
  }

  setOrgnizationReferenceNumber(e: string): void {
    this.transmittal.transmittal_create.OrganizationReferenceNumber = e;
  }

  setDocuemntType(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.transmittal.transmittal_create.DocumentTypeId = e?.id;
    }
  }

  setTransmittalDetail(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.transmittal.transmittal_create.TransmittalDetailId = e?.id;
    }
  }

  setNote(e: string): void {
    this.transmittal.transmittal_create.Note = e;
  }

  setRequireResponse(e: boolean): void {
    this.transmittal.transmittal_issued.RequireResponse = e;
  }

  setReceivedToday(e: boolean): void {
    this.transmittal.transmittal_issued.ReceivedToday = e;
  }

  setDate(e: Date | any): void {
    if (_.isNil(e) || e.type === "change") return;
    if (e.target?.value === "") {
      this.dueDate = null;
      this.transmittal.transmittal_issued.DueDate = null;
    } else {
      this.dueDate = new Date(e);
      this.transmittal.transmittal_issued.DueDate = convertDateToUTCIOSString(
        this.dueDate,
      );
    }
  }

  setReceivedDate(e: Date | any): void {
    if (_.isNil(e) || e.type === "change") return;
    if (e.target?.value === "") {
      this.receivedDate = null;
      this.transmittal.transmittal_issued.ReceivedDate = null;
    } else {
      this.receivedDate = new Date(e);
      this.transmittal.transmittal_issued.ReceivedDate =
        convertDateToUTCIOSString(this.receivedDate);
    }
  }

  setIsConfidential(e: boolean): void {
    this.transmittal.transmittal_issued.IsConfidential = e;
  }

  setTags(e: string): void {
    this.transmittal.transmittal_issued.Topics = e;
  }

  back(): void {
    if (this.hasChanges()) {
      this.baseFunctionService.backAfterConfirm();
    } else {
      this.baseFunctionService.back();
    }
  }

  showTransmittalDetail(): boolean {
    return (
      this.transmittal.transmittal_create.DocumentTypeId ===
      documentTypeTransmittalId
    );
  }
}
