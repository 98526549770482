import {
  ISelectItem,
  ModalContainerService,
} from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ITransmittalDocumentDownload,
  ITransmittalListItem,
} from "@api";
import AuthService from "@auth/auth.service";
import TransmittalRole from "@models/TransmittalRole";
import TransmittalIssue from "@models/transmittalIssue";
import IViewPrepare from "@models/IViewPrepaer";
import LoadingService from "@services/loading.service";
import LocalContractUserService from "@services/local-contractUser.service";
import {
  convertDateToUTCIOSString,
  getDateWithOffSet,
} from "@shared/utils";
import _ from "lodash";
import { Observable } from "rxjs";
import TransmittalStatus from "@models/TransmittalStatus";
import BaseFunctionService from "@services/baseFunction.service";
import TransmittalWithdrawStep from "@models/transmittalWithdrawStep";
import { WithdrawModalComponent } from "@shared/withdraw-modal/withdraw-modal.component";
import { BBJSelectUserService } from "@bbj/components";

@Component({
  selector: "app-issued",
  templateUrl: "./issued.component.html",
  styleUrls: ["./issued.component.scss"],
})
export default class IssuedComponent implements OnInit {
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  entity: ITransmittalListItem;

  currentUser: string;

  docs: ITransmittalDocumentDownload[] = [];

  isDbAdmin = false;

  transmittal: TransmittalIssue;

  oldTransmittal: TransmittalIssue;

  managersList: ISelectItem[] = [];

  transmittalTypeList: ISelectItem[] = [];

  canIssue = false;

  dueDate: Date | null = null;

  receivedDate: Date | null = null;

  today: Date;

  allTags: string[] = [];

  enableSaveDraft = false;

  isSubmittedStep = false;

  userSelectTitle = "cc";

  constructor(
    public router: Router,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public localContractUserService: LocalContractUserService,
    public activeModal: ModalContainerService,
    public baseFunctionService: BaseFunctionService,
    public bbjSelectUserService: BBJSelectUserService
  ) {}

  ngOnInit(): void {
    const data: IViewPrepare | undefined =
      this.activatedRoute.snapshot.data.itemData;
    this.currentUser = this.authService.user.Id;
    this.today = new Date();
    if (data) {
      const { item, documents, topics } = data;
      this.entity = item;
      this.docs = documents;
      this.isSubmittedStep = this.entity.Status === TransmittalStatus.Submitted;
      this.allTags = topics;
      this.oldTransmittal = new TransmittalIssue(
        this.entity.ContractId,
        this.entity,
      );
      this.dueDate = this.oldTransmittal.transmittal_issued.DueDate
        ? new Date(
            getDateWithOffSet(this.oldTransmittal.transmittal_issued.DueDate),
          )
        : null;
      this.receivedDate = this.oldTransmittal.transmittal_issued.ReceivedDate
        ? new Date(
            getDateWithOffSet(
              this.oldTransmittal.transmittal_issued.ReceivedDate,
            ),
          )
        : null;
      this.managersList = this.localContractUserService
        .getUsers([TransmittalRole.Manager])
        .map((u) => {
          return {
            id: u.UserId,
            name: u.UserInfo.displayName ?? "",
            checked: false,
          };
        });
      console.log(this.managersList);
      this.baseFunctionService.initCCUsers(this.userSelectTitle);
    }

    this.transmittal = JSON.parse(JSON.stringify(this.oldTransmittal));
  }

  hasChanges(): boolean {
    const old = JSON.stringify(this.oldTransmittal);
    const current = JSON.stringify(this.transmittal);
    return old !== current || this.bbjSelectUserService.hasChanges();
  }

  back(): void {
    if (this.hasChanges()) {
      this.baseFunctionService.backAfterConfirm();
    } else {
      this.baseFunctionService.back();
    }
  }

  IsValid(): boolean {
    return !_.isEmpty(this.transmittal.transmittal_issued.ManagerId);
  }

  submit(): void {
    this.canIssue = true;

    this.transmittal.transmittal_issued.IsDraft = false;
    this.update();
  }

  save(): void {
    this.canIssue = true;

    this.transmittal.transmittal_issued.IsDraft = true;
    this.update();
  }

  update(): void {
    this.loadingService.start();
    if (this.transmittal.transmittal_issued.ReceivedToday) {
      this.transmittal.transmittal_issued.ReceivedDate = null;
    }
    if (!this.transmittal.transmittal_issued.RequireResponse) {
      this.transmittal.transmittal_issued.DueDate = null;
    }
    this.transmittal.transmittal_cc_user = this.bbjSelectUserService.getSelectedUserIds(this.userSelectTitle);
    console.log(this.transmittal);

    this.baseFunctionService.update(this.transmittal);
  }

  setRequireResponse(e: boolean): void {
    this.transmittal.transmittal_issued.RequireResponse = e;
    
  }

  setReceivedToday(e: boolean): void {
    this.transmittal.transmittal_issued.ReceivedToday = e;
  }

  setManager(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.transmittal.transmittal_issued.ManagerId = e?.id;
    }
  }

  setDate(e: Date | any): void {
    if (_.isNil(e) || e.type === "change") return;
    if (e.target?.value === "") {
      this.dueDate = null;
      this.transmittal.transmittal_issued.DueDate = null;
    } else {
      this.dueDate = new Date(e);
      this.transmittal.transmittal_issued.DueDate = convertDateToUTCIOSString(
        this.dueDate,
      );
    }
    
  }

  setReceivedDate(e: Date | any): void {
    if (_.isNil(e) || e.type === "change") return;
    if (e.target?.value === "") {
      this.receivedDate = null;
      this.transmittal.transmittal_issued.ReceivedDate = null;
    } else {
      this.receivedDate = new Date(e);
      this.transmittal.transmittal_issued.ReceivedDate =
        convertDateToUTCIOSString(this.receivedDate);
    }
    
  }

  setIsConfidential(e: boolean): void {
    this.transmittal.transmittal_issued.IsConfidential = e;
    
  }

  withdraw(): void {
    const item = new TransmittalWithdrawStep(this.entity.ContractId, this.entity);
    const modalInstance = this.activeModal.open(WithdrawModalComponent);
    modalInstance.instance.setItem = item;
    modalInstance.result.then((result) => {
      if (result) {
        console.log(result);
        this.loadingService.start();
        this.baseFunctionService.update(item);
      }
    });
  }
}
