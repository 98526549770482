/* eslint-disable prettier/prettier */
/* eslint-disable prefer-destructuring */
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { TransmittalDocumentsService, TransmittalItemService } from "@api";
import { getLastestIssuedItem, getNavigationUrl, validDBViewPermission } from "@shared/utils";
import { HttpErrorResponse } from "@angular/common/http";
import AuthService from "../content/auth/auth.service";
import IViewPrepare from "../models/IViewPrepaer";
import LoadingService from "../services/loading.service";
import LocalContractUserService from "../services/local-contractUser.service";
import RouteParams from "../shared/route-params";
import BaseFunctionService from "@services/baseFunction.service";
import TransmittalStatus from "@models/TransmittalStatus";
import TransmittalRole from "@models/TransmittalRole";

@Injectable({
  providedIn: "root"
})
export default class ViewResolver implements Resolve<IViewPrepare | undefined> {
  constructor(
    public router: Router,
    private loadingService: LoadingService,
    private transmittalItemService: TransmittalItemService,
    private transmittalDocumentsService: TransmittalDocumentsService,
    public localContractUserService: LocalContractUserService,
    public authService: AuthService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IViewPrepare | undefined> {
    this.loadingService.start();
    const itemId = route.params[RouteParams.ItemId];
    const contractId = route.parent.params[RouteParams.ContractId];
    const createNewURL = `/${contractId}/create/new`;
    if(state.url === createNewURL)
    {
      this.baseFunctionService.setContractId(contractId);
      return of(undefined);
    }

    return forkJoin([
      this.transmittalItemService.getTransmittalItem(itemId),
      this.transmittalDocumentsService.getAllTransmittalDocuments(itemId),
      this.transmittalItemService.getTransmittalTopics(),
    ]).pipe(
      map(([item, documents, topicItems]) => {
        this.baseFunctionService.setEntity(item, documents);
        const filteredItems = validDBViewPermission(
          [item],
          this.localContractUserService,
          this.authService.user
        );
        const lastIssued = getLastestIssuedItem(item);
        const IsConfidential = !lastIssued || lastIssued.IsConfidential;
        const user = this.authService.user;
        const role = this.localContractUserService.currentUserContractRole;
        const fromUser =
          item.transmittal_create
            ?.correspondence_reference_user_correspondence_reference_userTotransmittal_create_From;
        const toUser =
          item.transmittal_create
            ?.correspondence_reference_user_correspondence_reference_userTotransmittal_create_To;
        const hasRole =
          user.Email === fromUser?.Email ||
          user.Email === toUser?.Email ||
          (item.transmittal_cc_user &&
            item.transmittal_cc_user.filter((cc)=>{return !cc.DeletedBy}).map((cc) => cc.UserId).includes(user.Id));
        const noView = item.Status === TransmittalStatus.Void ? role !== TransmittalRole.DocControl : item.ViewOnly === true && IsConfidential !== false && !hasRole;
        if(filteredItems.length === 0 || noView)
        {
          this.router.navigateByUrl(`/${item.ContractId}/list`);
        }
        const newURL = `/${getNavigationUrl(item)}`;
        if (state.url !== newURL) {
          this.router.navigateByUrl(newURL);
        }
        const topics = topicItems.map((t)=>t.Name);
        this.loadingService.stop();
        return { item, documents, topics };
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        this.router.navigate([contractId, "error", error.status ? error.status : 500]);
        return of(null);
      }),
    );
  }

}
